import React, { useState } from 'react'

import { Button, Form } from 'react-bootstrap'

import './Login.css'

const Login = ({ setRole, setIsLogin }) => {

    const [login, setLogin] = useState('')

    const [hint, setHint] = useState('')

    const handleLogin = () => {
        switch (login) {
            case 'Папа':
                setRole(1);
                setIsLogin(true);
                localStorage.setItem('login', '1');
                break;
            case 'Мама':
                setRole(2);
                setIsLogin(true);
                localStorage.setItem('login', '2');
                break;
            default: break;
        }

    }

    return (
        <div className='login'>
            <h2>Авторизация</h2>
            {hint && <span>Подсказка: Пароль - прилагательное</span>}
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Статус в семье</Form.Label>
                    <Form.Control type="text" placeholder="" value={login} onChange={(e) => setLogin(e.target.value)} />
                </Form.Group>
                <Button variant="primary" type="button" onClick={() => handleLogin()}>
                    Войти
                </Button>
            </Form>
        </div>
    )
}

export default Login


