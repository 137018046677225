import React from 'react'

import './Main.css'

const Main = () => {
  return (
    <div className='main'>
      <h1>Главная</h1>
      <div>
        <div className='main__card'>
          
        </div>
      </div>
    </div>
  )
}

export default Main
