import React from 'react'

import './Mom.css'

import main from '../assets/mom/main.webp'

const Mom = ({ imgs }) => {
  return (
    <div className='mom'>
      <h2>Фотокарточки лучшей мамы</h2>
      <img src={main} />
      <img src={imgs['1.webp']} />
      <img src={imgs['2.webp']} />
      <img src={imgs['3.webp']} />
      <img src={imgs['4.webp']} />
      <img src={imgs['5.webp']} />
      <img src={imgs['6.webp']} />
      <img src={imgs['7.webp']} />
      <img src={imgs['8.webp']} />
      <img src={imgs['9.webp']} />
      <img src={imgs['10.webp']} />
      <img src={imgs['11.webp']} />
      <img src={imgs['12.webp']} />
      <img src={imgs['13.webp']} />
      <img src={imgs['14.webp']} />
      <img src={imgs['15.webp']} />
      <img src={imgs['16.webp']} />
      <img src={imgs['17.webp']} />
      <img src={imgs['18.webp']} />
      <img src={imgs['19.webp']} />
      <img src={imgs['20.webp']} />

      <img src={imgs['food_1.webp']} />
      <img src={imgs['food_2.webp']} />

      <img src={imgs['21.webp']} />
      <img src={imgs['22.webp']} />
      <img src={imgs['23.webp']} />
      <img src={imgs['24.webp']} />
      <img src={imgs['25.webp']} />
      <img src={imgs['26.webp']} />
    </div>
  )
}

export default Mom
