import React, { useEffect, useState } from 'react';


import Navbar from './Navbar';
import Dad from './pages/Dad';
import Main from './pages/Main';
import Mom from './pages/Mom';
import Login from './pages/Login';

import './App.css';

//393 x 873

function importAll(r) {
  let images = {};
   r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
  return images
 }

 const dadImages = importAll(require.context('./assets/dad', false, /\.(png|jpe?g|svg|JPG)$/));
 const momImages = importAll(require.context('./assets/mom', false, /\.(webp)$/))

const App = () => {

  const [page, setPage] = useState(0)

  const [role, setRole] = useState(0)
  const [isLogin, setIsLogin] = useState(false)

  const handleLogOut = () => {
    localStorage.removeItem('login')
    setIsLogin(false);
    setPage(0);
    setRole(0);
    window.location.reload()
  }

  useEffect(() => {
    console.log(role);
    if(localStorage.getItem('login')) {
      setRole(localStorage.getItem('login'))
      setIsLogin(true)
    }
  }, [role])

  if (!isLogin) {
    return (
      <Login setRole={setRole} setIsLogin={setIsLogin} />
    )
  }

  return (
    <div className="app">
        <Navbar page={setPage} handleLogOut={handleLogOut} />
      
        {page == 0 && role >= 0 && <Main/> }
        {page == 1 && role == 1 && <Dad imgs={dadImages} /> }
        {page == 1 && role != 1 && 'Страница недоступна' }
        {page == 2 && role == 2 && <Mom imgs={momImages} /> }
        {page == 2 && role != 2 && 'Страница недоступна' }
      
      <footer>

      </footer>
    </div>
  );
}

export default App;
