import React, { useState } from 'react'

import { Button, ButtonGroup } from 'react-bootstrap';

import './Navbar.css'

const Navbar = ({ page, handleLogOut }) => {

    const [disabled, setDisabled] = useState(0)

    const handleClick = (i) => {
        page(i); setDisabled(i)
    }

    return (
        <div className='navbar'>
            <h2>Цифровые воспоминания</h2>
            <div className='navbar-navbtns'>
                <ButtonGroup className='navbar-navbtns'>
                    <Button variant="primary" onClick={() => { handleClick(0) }} disabled={disabled == 0} >Главная</Button>
                    <Button variant="primary" onClick={() => { handleClick(1) }} disabled={disabled == 1} >Папа</Button>
                    <Button variant="primary" onClick={() => { handleClick(2) }} disabled={disabled == 2} >Мама</Button>
                </ButtonGroup>
                <Button variant='danger' onClick={() => handleLogOut()} style={{marginLeft:'1em'}}>Выйти</Button>
            </div>
        </div>
    )
}

export default Navbar
