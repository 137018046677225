import React, { useEffect } from 'react'

import './Dad.css'

import main from '../assets/main.jpg'

import perevozka1 from '../assets/dad/1_1.png'
import perevozka2 from '../assets/dad/1_2.png'
import perevozka3 from '../assets/dad/1_3.png'
import perevozka4 from '../assets/dad/1_4.png'

const Dad = ({ imgs }) => {


  return (
    <div className='dad'>
      <h2>Фотокарточки лучшего папы</h2>
      <img src={main} />
      <img src={imgs['1.jpg']} />
      <img src={imgs['2.jpg']} />
      <img src={imgs['3.jpg']} />
      <img src={imgs['4.jpg']} />
      <img src={imgs['5.jpg']} />
      <img src={imgs['6.jpg']} />
      <img src={imgs['7.jpg']} />
      <img src={imgs['8.jpg']} />
      <img src={imgs['9.jpg']} />
      <img src={imgs['10.jpg']} />
      <img src={imgs['11.jpg']} />
      <img src={imgs['12.jpg']} />
      <img src={imgs['13.JPG']} />
      <img src={imgs['14.JPG']} />
      <img src={imgs['15.JPG']} />
      <img src={imgs['16.JPG']} />
      <img src={imgs['17.JPG']} />
      <img src={imgs['18.JPG']} />
      <img src={imgs['19.JPG']} />
      <img src={imgs['20.JPG']} />
      <img src={imgs['21.JPG']} />
      <img src={imgs['22.JPG']} />
      <img src={imgs['23.JPG']} />
      <img src={imgs['24.JPG']} />
      <img src={imgs['25.JPG']} />
      <img src={imgs['26.JPG']} />
      <img src={imgs['27.jpg']} />
      <img src={imgs['28.JPG']} />
      <img src={imgs['29.JPG']} />
      <img src={imgs['30.JPG']} />
      <img src={imgs['31.JPG']} />
      <img src={imgs['32.JPG']} />
      <img src={imgs['33.JPG']} />
      
      <img src={perevozka1} />
      <img src={perevozka2} />
      <img src={perevozka3} />
      <img src={perevozka4} />

      <img src={imgs['34.JPG']} />
      <img src={imgs['35.JPG']} />
      <img src={imgs['36.JPG']} />
      <img src={imgs['37.JPG']} />
      <img src={imgs['38.JPG']} />
      <img src={imgs['39.JPG']} />
      <img src={imgs['40.JPG']} />
      <img src={imgs['41.JPG']} />
      <img src={imgs['42.JPG']} />
      <img src={imgs['43.jpg']} />
      <img src={imgs['44.JPG']} />
      <img src={imgs['45.JPG']} />
      <img src={imgs['46.JPG']} />
      <img src={imgs['47.JPG']} />
      <img src={imgs['48.JPG']} />
      <img src={imgs['49.JPG']} />
      <img src={imgs['50.jpg']} />
      <img src={imgs['51.jpg']} />
      <img src={imgs['52.JPG']} />

    </div>
  )
}

export default Dad
